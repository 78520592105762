/* Lists */

.wildshape-list {
    list-style-type: none;
    margin-left: 0;
    max-width: 240px;
    margin: 0 auto;
}

.wildshape-list li {
    margin-bottom: 10px;
    cursor: pointer;
}

.challenge-rating-section {
    margin-bottom: 1.5em;
}

.challenge-rating-section h3 {
    text-align: center;
    cursor: pointer;
}

.challenge-rating-body {
    display: none;
}

.challenge-rating-body.show-true {
    display: block;
    margin-top: 1em;
}

.challenge-ratings {
    height: 390px;
    overflow-y: scroll;
}

@media only screen and (min-width: 800px) {
    .wildshape-list {
        width: 100%;
        margin: 0;
        padding-left: 15px;
    }

    .challenge-rating-section h3 {
        text-align: left;
    }
}

@media only screen and (min-height: 800px) {
    .challenge-ratings {
        height: 600px;
        overflow-y: auto;
        padding-right: 10px;
    }
}