/* Container */

body {
    background-color: #1D3C51;
    /*background-color: @bkblue;*/
}

.App {
    color: white;
    margin: 0 auto;
}

.sm-hidden {
    display: none;
}

@media only screen and (min-width: 800px) {
  #druidContainer {
      display: grid;
      column-gap: 1em;
      grid-template-columns: 270px auto;
      padding: 0 20px;
  }

  .sm-hidden {
      display: block;
  }
}

.App:after {
    content: "";
    clear: both;
    display: table;
}