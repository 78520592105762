/* Beast Details */
.beast-details-stats {
    line-height: 1.4em;
    border: 1px solid #fff;
    padding: 20px 15px;
}

.beast-details-stats p, .beast-details-stats div {
    margin-bottom: 15px;
}

.beast-details {
    margin: 0 1em;
    max-width: 830px;
}


.back-button {
    display: inline-block;
    border: none; 
    background: none;
    color: #fff;
    padding-bottom: 15px;
}

.beast-details h2 {
    margin-bottom: 0.5em;
}

.beast-details h3 {
    font-size: 1em;
    font-style: italic;
    text-align: center;
    margin-bottom: 1em;
}

@media only screen and (min-width: 800px) {
    .beast-details {
        margin: 0;
    }

    .back-button {
        display: none;
    }

    .beast-details h3 {
        text-align: left;
    }
}